.brandItemWrapper{
    margin-top: 10px;
    margin-bottom: 10px;
    max-width: 200px;
    margin-left: auto;
    margin-right: auto;
}

.myHeader{
    font-size: 20px;
    padding-left: 10px;
    
}



 
  
  @media (min-width: 1200px) {
   
  
    .myHeader{
      text-align: center;
      font-size: 30px;
      padding-left: 0px;
    }
  
  }
  