.headWrapper{
  margin-bottom: 20px;
  text-align: left;
}
.lineWrapper{
  margin-top:10px;
  /* vertical-align: middle; */
  display: flex;
  align-items: center;
}

.qrWrapper{
   height: 100%;
   width: 100%;
   /* background-color: red; */
   text-align: left;
   margin-top: 20px;
}
.qrWrapper img{
    width: 100px;
    height: auto;
}
