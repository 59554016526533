.wrapper{
    padding-top: 80px;
    padding-bottom: 60px;
}

.header{
    font-size:22px;
    margin-bottom: 20px;
    text-align: center;
}

.formWrapper{
    background-color: white;
    padding: 30px 20px 14px 20px;
    border-radius: 7px;
    width: auto;
}

.btnAddress{
    margin-top: 10px;
}