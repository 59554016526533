
.ais-InstantSearch{
  padding-top: 90px;
  padding-bottom: 20px;
}

.clamper{
  display: -webkit-box;
   -webkit-line-clamp: 3;
   -webkit-box-orient: vertical;
   overflow: hidden;
}
.clamper-1{
  display: -webkit-box;
   -webkit-line-clamp: 1;
   -webkit-box-orient: vertical;
   overflow: hidden;
}

.stateWrapper{
  padding-top: 10px;
  padding-bottom: 10px;
}

.notFoundImage{
  margin-top: 20px;
  width: 100%;
  height: auto;
}


.link{
  text-decoration: none;
  /* color:none; */
  color: #5a5a5a;
}
.link:hover{
  text-decoration: none;
  /* color:#d34444; */
}


/* .link:hover *{
  color:none;
} */
.link:hover h4{
  text-decoration: underline;
  /* color:#d34444; */
}
.link:hover p{
  /* text-decoration: underline; */
  /* color:#d34444; */
  /* background-color: yellow; */
  color:#5a5a5a;
}

.productImage{
  width: 100%;
  height: auto;
  margin-left: auto;
}
.productWrapper{
  background-color: white;
  border-bottom: solid #c9c9c9 1px;
  padding-top: 15px;
  padding-bottom: 15px;
}
.productDetailwrapper{
    width: 95%;
    margin-right: auto;
}

/* .productPrice{
  margin-bottom: 0px;
}
.productDetail{
  font-size: 14px;
} */

.productPrice{
  color:#d34444;
}
/* .productPrice:hover{
  color:#d34444 !important;
} */

.productDetailwrapper h4{
  font-size: 20px;  
  margin-bottom: 5px;
  padding-bottom: 0px;
  margin-top: 0;
  padding-top: 0;
  /* color:#d34444; */
  color:black;
}
.productDetailwrapper p{
  margin-bottom: 0px;
}


.clamper{
  display: -webkit-box;
   -webkit-line-clamp: 2;
   -webkit-box-orient: vertical;
   overflow: hidden;
}
 
 .product-hit-container{
  margin-top: 20px;
  margin-bottom: 20px;
} 

.left-panel{
  margin-top: 85px;
}
