.wrapper{
    padding-top: 80px;
    padding-bottom: 60px;
    width: 100%;
   
}


.header{
    font-size:22px;
    margin-bottom: 20px;
    text-align: center;
}
.locationItemWrapper{
    padding: 15px 0px;
    background-color: white;
    margin-bottom: 15px;
    border-radius: 5px;
}
.newAddress{
    background-color: white;
    padding: 5px 15px 5px 8px;
    border-radius: 5px;
    font-size: 18px;
    font-weight: 500;
}
.newAddress:hover{
    cursor: pointer;
}
.addressWrapper{
    background-color:thistle;
    padding-top: 50px;
    padding-bottom: 30px;
}
.billingAddressWrapper{
    background-color: #eda6a6;
    padding-top: 50px;
    padding-bottom: 30px;
}

/* 
.tbSummary td,th{
 border:solid black 1px;
} */

.basketSummaryWrapper table{
    width: 100%;
}
.basketSummaryWrapper tbody tr{
    border-bottom: solid #c9c9c9 1px;
}

.basketSummaryWrapper table td{
    /* border:solid black 1px; */
    /* vertical-align: text-top; */
    /* border-bottom: solid #c9c9c9 1px; */
    vertical-align:bottom;
}

.basketSummaryWrapper tfoot tr td{
    padding-top: 10px;
}

.btnBlack{
    background-color: #5a5a5a;
    color:white;
}
.btnBlack:hover{
    background-color: #3b3b3b;
    color:white;
}
.confirmWrapper{
    padding: 15px 0px 10px 10px;
}
.confirmWrapper button{
    width: 100px;
}

.transportationWrapper{
    padding-top: 10px;
    padding-bottom: 10px;
}
.summaryAddress{
    /* padding-left: 30px; */
    /* padding-left: 20px; */
    /* padding-bottom: 20px; */
    padding-top: 20px;
}
.summaryAddress table{
    /* margin-left: 30px; */
}
.summaryAddress table tr td{
    vertical-align: text-top;
}
.transportationSummary{
    padding-top: 20px;
}
.transportationSummary p{
    margin-left: 10px;
}   

.summaryAddress table tr td:first-child{
    width: 120px;
}


/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
    .summaryAddress table{
        margin-left: 30px;
    }
    .transportationSummary p{
        margin-left: 40px;
    } 
}

/* Medium devices (tablets, 768px and up) The navbar toggle appears at this breakpoint */
@media (min-width: 768px) {

}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {

}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {

}



/*
::::::::::::::::::::::::::::::::::::::::::::::::::::
Custom media queries
*/

/* Set width to make card deck cards 100% width */
@media (max-width: 950px) {

}
