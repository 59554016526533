.wrapper{
    padding-top: 80px;
    padding-bottom: 40px;
  
}


.formWrapper{
    padding: 20px 20px;
    background-color: white;
    border-radius: 5px;
}