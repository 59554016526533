.wrapper{
    padding-top: 80px;
    padding-bottom: 60px;
    /* background-color: red; */
}

.header{
    /* background-color:white; */
    padding: 5px 10px;
}
.header h4{
    font-weight: 400;
}
.spinnerWrapper{
    margin-top: 30px;
    /* background-color: red; */
    text-align: center;
}


.noPurchaseWrapper{
    padding-top: 30px;
}






ul.timeline {
    list-style-type: none;
    position: relative;
}
ul.timeline:before {
    content: ' ';
    background: #d4d9df;
    display: inline-block;
    position: absolute;
    left: 29px;
    width: 2px;
    height: 100%;
    z-index: 400;
}
ul.timeline > li {
    margin: 20px 0;
    padding-left: 20px;
}
ul.timeline > li:before {
    content: ' ';
    background: white;
    display: inline-block;
    position: absolute;
    border-radius: 50%;
    border: 3px solid #22c0e8;
    left: 20px;
    width: 20px;
    height: 20px;
    z-index: 400;
}