.numbarWrapper{
  width: 100%;
  /* background-color: red; */
  height: 38px;
}
.numbarWrapper input{
  width: 80%;
  text-align: center;
  border-radius: 0px;
  border:solid #b1b1b1 1px;
}
.numbarWrapper button{
  background-color: #ffeaea;
  border:solid #b1b1b1 1px;
  /* border-radius: 2px; */
}

.numbarWrapper button:disabled{
  background-color: #fafafa;
  color:#e0e0e0;
  /* background-color: red; */
}
