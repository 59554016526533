.topBar {
  height: 56px;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: white;
  border-bottom: solid #f6f6f6 1px;
  padding: 0 10px;
  /* overflow-y: hidden; */
  z-index: 101;
}

.logo{
  padding: 10px;
  height: 100%;
  box-sizing: border-box;
  border-radius: 5px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
}
.logo:hover{
  cursor: pointer;
}
.logo img {
    height: 100%;
}

.menuButton{

  border:solid 2px black;
  padding: 0px 3px;
  background-color: transparent;
  border: none;
}



.topbarIcon{
  width: 24px;
  height: 24px;
}
.badge{
  padding: 5px 6px;
  font-weight: normal;
}
