.SideDrawer {
    position: fixed;
    width: 280px;
    max-width: 70%;

    height: 100%;
    overflow-y: auto;
    padding-bottom: 100px;

    left: 0;
    top: 56px;
    z-index: 200;
    background-color: #3b3b3b;
    box-sizing: border-box;
    transition: transform 0.3s ease-out;

}

.Open {
    transform: translateX(0);
}

.Close {
    transform: translateX(-100%);
}

.Logo {
    height: 11%;
    margin-bottom: 32px;
}


.SideDrawer::-webkit-scrollbar {
    /* height: 4px; */
    width: 10px;
}

.SideDrawer::-webkit-scrollbar-track {

    background-color: transparent;
}

.SideDrawer::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background-color: rgba(90, 90, 90, 1);
}

.collapseList{
  padding-left: 30px;
}
.sectionWrapper{
   padding-top: 10px;
   /* padding-left: 15px; */
   padding-bottom: 10px;
   border-bottom:solid white 1px;
}
.sectionWrapper:last-child{
  border:none;
}

