.wrapper{
    padding-top: 80px;
    padding-bottom: 60px;
}

.header{
    font-size:22px;
    margin-bottom: 20px;
    text-align: center;
}
.locationItemWrapper{
    padding: 15px 0px;
    background-color: white;
    /* margin-bottom: 15px; */
    border-radius: 5px;
    margin-top: 15px;
}

.newAddress{
    background-color: white;
    padding: 5px 15px 5px 8px;
    /* margin-bottom: 20px; */
    border-radius: 5px;
    font-size: 18px;
    font-weight: 500;
}
.newAddress:hover{
    cursor: pointer;
}