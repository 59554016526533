
.firstRow{
  padding-top: 90px;
}

.secondRow{
  margin-top: 20px;
}



/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {

  .firstRow{
    padding-top: 100px;
  }

  .secondRow{
    margin-top: 50px;
  }

}
