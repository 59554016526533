

.menuItemWrapper{
    padding-left: 12px;
    padding-top: 5px;
    padding-bottom: 5px;
    margin-top: 5px;

    /* padding: 5px 5px; */
    position: relative;
}
.menuItemWrapperHighLight{
  background-color: #D34444;
}

.menuItemWrapper button{
  background-color: transparent;
  border: none;
  color: white;
  vertical-align: text-bottom;
}

.menuItemWrapper span{
  padding-left: 5px;
  font-size: 15px;
  font-weight: 200;
}


.clamper{
 overflow: hidden;
 white-space: nowrap;
}


.imgIcon{
  width: 24px;
  height: 24px;
}
.arrow{
  width: 12px;
  height: 12px;
  position: absolute;
  right: 10px;
  top:50%;
  /* top: -10px; */
  /* top:50%; */
  transform: translateY(-50%);
}



@media (min-width: 992px) {
  .menuItemWrapper:hover{
     cursor: pointer;
     background-color: #5a5a5a;
  }

}
