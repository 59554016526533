.wrapper{
    padding-top: 90px;
    padding-bottom: 40px;
    max-width: 400px;
    margin: auto;
}

.notFoundWrapper{
    width: 100%;
}



@media (min-width: 576px) {

    .myCowrapperntainer{
      max-width: 400px;
    }
  
  }
  
  @media (min-width: 768px) {
  
    .wrapper{
      max-width: 600px;
    }
  
  }
  
  @media (min-width: 992px) {
  
    .wrapper{
      max-width: 800px;
    }
  
  }
  
  @media (min-width: 1200px) {
    .wrapper{
      max-width: 1200px;
    }
  
  }
  