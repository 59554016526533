
.inputWrapper{

}

.validator{
  position: relative;
  top:-2px;
  left:  -25px;
}
