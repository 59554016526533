.likeIcon{
  position: relative;
  top:-3px;
  right: 10px;
}
.link{
  text-decoration: none;
  /* color:#d34444;
   */
   color:black;
}
.link:hover{
  text-decoration: none;
  /* color:#d34444; */
  color:black;
}

.tile{
  width: 163px;
  height: 260px;
  background-color: white;
  padding-bottom: 15px;
  margin: auto;
}
.tile:hover{
  cursor: pointer;
  box-shadow: 0 0 11px rgba(33,33,33,.2);
}

.tile img{
  width: 100%;
  height: auto;
}

.header{
  margin-top: 10px;
  margin-left: 8px;
  font-size: 15px;
  line-height: 22px;
}

.clamper{
  display: -webkit-box;
   -webkit-line-clamp: 1;
   -webkit-box-orient: vertical;
   overflow: hidden;
   /* background-color: red; */
   width: 95%;
}



.price{
  font-size: 15px;
  /* color:#d34444; */
  margin-top: 8px;
  margin-left: 8px;
  padding-bottom: 12px;
  color:#d34444;
}

.priceDiscounted{
  text-decoration: line-through;
}

/* @media (min-width: 576px) {

  .tileWrapper {
    width: 168px;
    height: 260px;
  }


} */
