.ImageOptions{
  white-space:nowrap;
  overflow-x: auto;
  padding-bottom: 5px;
}


.ImageWrapper{
  display: inline;
  margin-right: 3px;
}
.ImageWrapper img{
  width: 20%;
  height: auto;

}
.ImageWrapper img:hover{
  cursor: pointer;
  border:solid black 1px;
}


.ImageActive img{
  border: solid red 1px;
}

.ImageOptions::-webkit-scrollbar {
    height: 8px;
}

.ImageOptions::-webkit-scrollbar-track {

    background-color: transparent;
}

.ImageOptions::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background-color: rgba(0, 0, 0, 0.2);;
}
