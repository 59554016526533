.wrapper{
  padding-top: 60px;
  padding-bottom: 40px;
}

.imageOptionRow{
  margin-top: 10px;
  margin-bottom: 10px;

}
/* 
.productMainWrapper{
  padding-left: 0px;
}
 */

 .option{
  /* border: solid re */
  padding: 5px 10px;
  /* text-align: left; */
  /* vertical-align:top; */

  background-color: white;
  border: solid #fc9898 1px;
  border-radius: 2px;
  width: 49%;

  margin-bottom: 5px;
  /* margin-top: 5px; */
  /* margin-right:10px; */


  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;


}

.option:hover{
  cursor: pointer;
  background-color: #ffeaea;
  color: #fc9898;
}
.selectedOptionIndex{
  background-color: #d34444;
  color:white;
}



  
.btnBasket{
  background-color: #3b3b3b;
  color:white;
  border: none;
  padding: 10px 10px;
  width: 49%;
  margin-right: 2%;
}
.btnBasket:disabled{
  background-color: grey;

}

.btnWish{
   background-color: #d34444;
   color:white;
   border: none;
   padding: 10px 10px;
   width: 49%;
}
.btnWish:disabled{
  background-color:grey;
}


.productMainWrapper{
  padding-left: 0px;
}
.productDetailWrapper{
  margin-top: 10px;
}


  
  /*---------------------------container--------------------------- */
  .productTileContainer{
    max-width: 400px;
    margin: auto;
    margin-top: 30px;
    margin-bottom: 30px;
  }
  
  
  @media (min-width: 576px) {
  
    .productTileContainer{
      max-width: 400px;
    }
  
  }
  
  @media (min-width: 768px) {
  
    .productTileContainer{
      max-width: 600px;
    }
  
  }
  
  @media (min-width: 992px) {
  
    .productTileContainer{
      max-width: 800px;
    }
    .productMainWrapper{
      padding-left: 40px;
    }
  
  }
  
  @media (min-width: 1200px) {
    .productTileContainer{
      max-width: 1200px;
    }
  
  }
  