.myContainer{
    max-width: 400px;
    margin: auto;
    padding-top: 80px;
    padding-bottom: 60px;
  }
  
  
  
  
  @media (min-width: 576px) {
  
    .myContainer{
      max-width: 400px;
    }
  
  }
  
  @media (min-width: 768px) {
  
    .myContainer{
      max-width: 600px;
    }
  
  }
  
  @media (min-width: 992px) {
  
    .myContainer{
      max-width: 800px;
    }
  
  }
  
  @media (min-width: 1200px) {
    .myContainer{
      max-width: 1200px;
    }
  
  }
  