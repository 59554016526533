

.containerWrapper{
    padding-top: 80px;
    padding-bottom: 60px;
    
}


.wrapper{
    border-radius: 5px;
    background-color: white;
    padding: 30px 20px;
}