.myContainer{
    max-width: 400px;
    margin: auto;
  }
  
  .myHeader{
    font-size: 20px;
    padding-left: 20px;
    
  }
  
  .spinnerWrapper{
    margin-top: 30px;
    /* background-color: red; */
    text-align: center;
  }
  
  
  
  
  @media (min-width: 576px) {
  
    .myContainer{
      max-width: 400px;
    }
  
  }
  
  @media (min-width: 768px) {
  
    .myContainer{
      max-width: 600px;
    }
  
  }
  
  @media (min-width: 992px) {
  
    .myContainer{
      max-width: 800px;
    }
  
  }
  
  @media (min-width: 1200px) {
    .myContainer{
      max-width: 1200px;
      
    }
  
   
  }
  