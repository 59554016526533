.purchaseOrderWrapper{
    background-color: white;
    padding: 20px 20px;
    border-radius: 5px;
    margin-bottom: 20px;
}

.btnSlipWrapper{
    margin-top: 10px;
}

.purchaseOrderWrapper table td{
    vertical-align: top;

}

.cancelled{
    text-decoration: line-through;
    color:red;
}