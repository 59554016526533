.container{
  max-width: 1300px;
  margin: auto;
  /* margin-top: 100px; */
  padding-top: 90px;
}

.logoWrapper{
  height: 100%;
  width: 100%;
}
.logoWrapper img{
    width: 80%;
    margin: auto;
    max-width: 600px;
}


.formWrapper{
  max-width: 400px;
}


/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {

  .formWrapper{
    max-width: 450px;
  }



}
