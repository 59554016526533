.bankIcon{
   width: 25px;
   height: 25px;
}

/* .bankList li{
    margin-bottom: 12px;
   
} */

/* .bankList li span{ 
    padding-left: 8px;
} */
.bankList{
    width: auto;
    max-width: 300px;
    margin-left: 30px;
    /* margin: auto; */
    margin-bottom: 12px;
    margin-top: 12px;
}
.bankList th,td{
    padding: 6px 8px 6px 8px;
}
.bankList td span{
    padding-left: 12px;
    
}

.videoWrapper{
    padding-top: 40px;
    padding-bottom: 30px;
    max-width: 600px;
    margin: auto;
}

.header{
    padding-bottom: 12px;
}