.barcodeWrapper{
  width: 100%;
  height: auto;
  /* background-color: red; */
  text-align: center;
}

.barcodeWrapper img{
  width: 200px;
  height: auto;
}
