.BrandImage{
    width: 100%;
    height: auto;
}

.BrandImage:hover{
    cursor: pointer;
}

.BrandImage img{
    width: 100%;
}