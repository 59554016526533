.wrapper{
    padding-top: 80px;
    padding-bottom: 40px;
}

.PurchaseOrderShowWrapper{
    background-color: white;
    padding:30px 15px 10px 15px;
}

.PurchaseOrderShowWrapper table{
    /* width: 100%; */
    /* width: auto; */
    width: 100%;
    margin-top: 5px;
}

.PurchaseOrderShowWrapper table td{
    padding: 7px;
    /* border: solid black 1px; */
    
}
.PurchaseOrderShowWrapper table tr td:first-child{
    /* border:solid black 1px; */
    /* min-width: 320px; */
}
.PurchaseOrderShowWrapper table tr td{
    /* border:solid black 1px; */
    vertical-align: text-top;
}
.PurchaseOrderShowWrapper table tr:nth-child(even) td{
    padding-bottom: 20px;
}
.PurchaseOrderShowWrapper table tfoot tr td{
    border-top: solid #c9c9c9 1px;
    
    padding-top: 20px;
}


.attachReceiptContentWrapper{
    background-color: white;
    /* padding: 10px 10px; */
    padding: 0px 15px 10px 15px;
}
.attachedReceiptWrapper{
    width: 50%;
    margin: auto;
    padding-top: 30px;
    padding-bottom: 30px;
}

.attachedReceiptWrapper img{
    width: 100%;
    height: auto;
    margin: auto;
}

.btnAttachReceipt{
    padding: 0px 0px 20px 0px;
}

.transportationContentWrapper{
    background-color: white;
    padding: 0px 15px 10px 15px;
}

.transportationImageWrapper{
    width: 50%;
    margin: auto;
    margin-bottom: 40px;
}
.transportationImageWrapper img{
    width: 100%;
}

.timelineContentWrapper{
    background-color: white;
    padding: 0px 15px 15px 15px;
}
.timeline td:first-child{
    /* min-width: 180px; */
    padding-left: 20px;
}
.timeline td{
    /* border: solid black 1px; */
    vertical-align: text-top;
}

.sectionDivider{
    background-color: #c9c9c9;
    
    /* height: 0.5px; */
}
