
.header{

  font-size:20px;
  padding-left: 20px;
  /* background-color: #3b3b3b; */
  color:white;
  /* position: relative; */
}
.wrapper {

  overflow-x: scroll;
  width: 100%;
  overflow-y: hidden;
  padding-top: 20px;
  background-color: #3b3b3b;
  padding-bottom: 10px;
}
.tileWrapper {

  position: relative;
  display: inline-block;
  float: left;
  margin-left: 20px;
}
.innerwrapper {
  width:1900px;
  height: 260px;
  position: relative;
}


.wrapper::-webkit-scrollbar {
    height: 4px;
}

.wrapper::-webkit-scrollbar-track {

    background-color: transparent;
}

.wrapper::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background-color: rgba(0, 0, 0, 0.2);;
}




@media (min-width: 576px) {

 
}

@media (min-width: 768px) {

  

}

@media (min-width: 992px) {


}

@media (min-width: 1200px) {
 

  .header{
    text-align: center;
    font-size: 30px;
    padding-left: 0px;
  }

}

