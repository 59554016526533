
.rowWrapper{
  padding: 8px 0px;
  border-bottom: solid #c9c9c9 1px;
  padding-top: 15px;
  padding-bottom: 15px;
}
.colWrapper{
  margin-bottom: 10px;

}
.firstColWrapper{
  /* padding-top:10px; */
}
.imageWrapper{
  width: 100%;
  /* background-color: red; */
}

.productImage{
   width: 80px;
   height: auto;
}

.inputQty{
  width: 100%;
  max-width: 90px;
  text-align: center;
  border-radius:3px;
  border: solid #c9c9c9 1px;
  /* display: inline-block; */
}
.basketCheckboxWrapper{
  /* border-right: solid #f9f9f9 1px; */
  /* background-color: red; */
}
.btnOption{
  background-color: transparent;
  border: none;
  padding: 0px 0px;
  margin-right: 15px;
}

.optionWarpper{
  background-color:#d34444;
  color:white;
  padding: 5px 12px;
  font-size: 13px;
  border-radius: 5px;
  
  width: 120px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  
}
.optionWarpper:hover{
  cursor: pointer;
}
