.wrapper:hover{
    cursor: pointer;
}

.name{
    font-size: 18px;
    font-weight: 500;
}

.deleteItemWrapper:hover{
    cursor: pointer;
}
.updateLocationIndexWrapper:hover{
    cursor: pointer;
}